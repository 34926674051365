import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js';
import '../styles/pages/PropertyBreakdown.css';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const PropertyBreakdown = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  useEffect(() => {
    if (!location.state?.property) {
      navigate('/');
      return;
    }
    setProperty(location.state.property);
    setFilteredProfiles(location.state.property.profiles);
  }, [location, navigate]);

  useEffect(() => {
    if (!property) return;
    
    const filtered = property.profiles.filter(profile => {
      const emailMatch = profile.email ? 
        profile.email.toLowerCase().includes(searchTerm.toLowerCase()) : 
        false;
      
      const valueStr = profile.value === null ? 'null' :
        typeof profile.value === 'object' ? 
          JSON.stringify(profile.value) : 
          String(profile.value);
      
      const valueMatch = valueStr.toLowerCase().includes(searchTerm.toLowerCase());
      
      return emailMatch || valueMatch;
    });
    setFilteredProfiles(filtered);
  }, [searchTerm, property]);

  const generateChartData = () => {
    if (!property || !property.profiles || property.profiles.length === 0) return null;

    // Count unique values
    const valueCount = property.profiles.reduce((acc, profile) => {
      const value = profile.value === null ? 'null' :
        typeof profile.value === 'object' ? JSON.stringify(profile.value) : String(profile.value);
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});

    // Generate colors for each unique value
    const colors = Object.keys(valueCount).map((_, index) => {
      const hue = (index * 137.5) % 360; // Golden angle approximation
      return `hsl(${hue}, 70%, 60%)`;
    });

    return {
      labels: Object.keys(valueCount),
      datasets: [{
        data: Object.values(valueCount),
        backgroundColor: colors,
        borderColor: colors.map(color => color.replace('60%', '50%')),
        borderWidth: 1
      }]
    };
  };

  const generateValueStats = () => {
    if (!property || !property.profiles || property.profiles.length === 0) return {};

    // Count unique values
    const valueCount = property.profiles.reduce((acc, profile) => {
      const value = profile.value === null ? 'null' :
        typeof profile.value === 'object' ? JSON.stringify(profile.value) : String(profile.value);
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});

    return valueCount;
  };

  const generateColor = (index) => {
    const hue = (index * 137.5) % 360; // Golden angle approximation
    return `hsl(${hue}, 70%, 60%)`;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 15,
          padding: 15
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} (${percentage}%)`;
          }
        }
      }
    }
  };

  if (!property) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="property-breakdown">
      <div className="header">
        <button className="back-button" onClick={() => navigate('/')}>
          ← Back to Properties
        </button>
      </div>

      <h1 className="property-title">{property.name}</h1>

      <div className="content-grid">
        <div className="values-section">
          <h3>Values</h3>
          <div className="values-table">
            <table>
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Total</th>
                  <th>Key</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(generateValueStats()).map(([value, count], index) => (
                  <tr key={value}>
                    <td>{value}</td>
                    <td>{count}</td>
                    <td>
                      <div 
                        className="color-key" 
                        style={{ backgroundColor: generateColor(index) }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="chart-section">
          <div className="chart-container">
            {generateChartData() && (
              <Pie data={generateChartData()} options={chartOptions} />
            )}
          </div>
        </div>
      </div>

      <div className="profiles-section">
        <h3>Profiles</h3>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by email or value..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <div className="search-results-count">
            {filteredProfiles.length} profiles found
          </div>
        </div>
        <div className="table-wrapper">
          <table className="data-table">
            <thead>
              <tr>
                <th>Profile</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {filteredProfiles.map(profile => (
                <tr key={profile.id}>
                  <td>{profile.email}</td>
                  <td>{profile.value === null ? 'null' :
                    typeof profile.value === 'object' ? 
                      JSON.stringify(profile.value) : 
                      String(profile.value)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PropertyBreakdown;
