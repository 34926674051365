import React, { useState } from 'react';
import '../styles/ContentLayout.css';
import '../styles/pages/LinkGenerator.css';

const LinkGenerator = () => {
  const [property, setProperty] = useState('');
  const [value, setValue] = useState('');
  const [link, setLink] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');
  const [copied, setCopied] = useState(false);

  const handleGenerateLink = () => {
    const newLink = `{% update_property_link '${property}' '${value}' '${link}' %}`;
    setGeneratedLink(newLink);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generatedLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="content">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Link Generator</h3>
          <div className="form-group">
            <label>Custom Property</label>
            <input
              type="text"
              className="form-control"
              value={property}
              onChange={(e) => setProperty(e.target.value)}
              placeholder="Enter custom property name"
            />
          </div>
          <div className="form-group">
            <label>Value</label>
            <input
              type="text"
              className="form-control"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter the value to set"
            />
          </div>
          <div className="form-group">
            <label>Landing Page</label>
            <input
              type="text"
              className="form-control"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="Enter the landing page URL"
            />
          </div>
          <button 
            onClick={handleGenerateLink} 
            className="btn btn-success"
            disabled={!property || !value || !link}
          >
            Generate Link
          </button>
          
          {generatedLink && (
            <div className="output">
              <h4>Generated Link:</h4>
              <pre>{generatedLink}</pre>
              <button
                onClick={handleCopy}
                className="btn btn-outline-secondary"
              >
                {copied ? 'Copied!' : 'Copy to Clipboard'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkGenerator;
