import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { 
  IconBold, IconItalic, IconH1, IconH2, IconList, IconListNumbers,
  IconLink, IconUnlink, IconPhoto, IconAlignLeft, IconAlignCenter,
  IconAlignRight, IconCode, IconTable, IconEdit, IconDeviceFloppy,
  IconEye
} from '@tabler/icons-react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import '../styles/pages/UserGuide.css';

const MenuBar = ({ editor, onSave, isEditing, addImage }) => {
  if (!editor || !isEditing) {
    return null;
  }

  const setLink = () => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('Enter the URL', previousUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  };

  const addTable = () => {
    editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
  };

  return (
    <div className="editor-menu">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
        title="Bold"
      >
        <IconBold size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
        title="Italic"
      >
        <IconItalic size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        title="Heading 1"
      >
        <IconH1 size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        title="Heading 2"
      >
        <IconH2 size={20} />
      </button>
      <div className="editor-menu-divider" />
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
        title="Bullet List"
      >
        <IconList size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
        title="Numbered List"
      >
        <IconListNumbers size={20} />
      </button>
      <div className="editor-menu-divider" />
      <button
        onClick={setLink}
        className={editor.isActive('link') ? 'is-active' : ''}
        title="Add Link"
      >
        <IconLink size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive('link')}
        title="Remove Link"
      >
        <IconUnlink size={20} />
      </button>
      <button onClick={addImage} title="Add Image">
        <IconPhoto size={20} />
      </button>
      <div className="editor-menu-divider" />
      <button
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        title="Align Left"
      >
        <IconAlignLeft size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
        title="Align Center"
      >
        <IconAlignCenter size={20} />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
        title="Align Right"
      >
        <IconAlignRight size={20} />
      </button>
      <div className="editor-menu-divider" />
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
        title="Code Block"
      >
        <IconCode size={20} />
      </button>
      <button onClick={addTable} title="Insert Table">
        <IconTable size={20} />
      </button>
      <div className="editor-menu-divider" />
      <button onClick={onSave} className="save-button" title="Save">
        <IconDeviceFloppy size={20} />
      </button>
    </div>
  );
};

const UserGuide = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: true,
      }),
      Image.configure({
        inline: true,
      }),
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
    ],
    editable: isEditing,
    content: content,
    autofocus: 'end',
    onUpdate: ({ editor, transaction }) => {
      // Only update content if it's a user input
      if (transaction.docChanged && transaction.getMeta('source') !== 'remote') {
        const newContent = editor.getHTML();
        if (newContent !== content) {
          const { from, to } = editor.state.selection;
          setContent(newContent);
          // Restore cursor position after state update
          requestAnimationFrame(() => {
            editor.commands.setTextSelection({ from, to });
          });
        }
      }
    },
  });

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      const selection = editor.state.selection;
      editor.commands.setContent(content, false, { preserveWhitespace: true });
      
      // Restore selection if it exists
      if (selection) {
        requestAnimationFrame(() => {
          editor.commands.setTextSelection(selection);
        });
      }
    }
  }, [editor, content]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditing);
    }
  }, [isEditing, editor]);

  const handleImageUpload = async (file) => {
    try {
      console.log('Starting image upload for file:', file.name);
      
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `user-guide/${fileName}`;

      console.log('Uploading to path:', filePath);

      // Upload the file
      const { error: uploadError, data: uploadData } = await supabase.storage
        .from('images')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) {
        console.error('Error uploading:', uploadError);
        throw uploadError;
      }

      console.log('Upload successful:', uploadData);

      // Get the public URL
      const { data } = supabase.storage
        .from('images')
        .getPublicUrl(filePath);

      console.log('Generated public URL:', data.publicUrl);
      return data.publicUrl;
    } catch (error) {
      console.error('Error in handleImageUpload:', error);
      alert('Failed to upload image. Please try again.');
      return null;
    }
  };

  const addImage = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        console.log('File selected:', file);
        const imageUrl = await handleImageUpload(file);
        console.log('Received image URL:', imageUrl);
        if (imageUrl) {
          editor.chain().focus().setImage({ src: imageUrl }).run();
          console.log('Image inserted into editor');
        }
      }
    };
    
    input.click();
  };

  const saveContent = async () => {
    try {
      const { error } = await supabase
        .from('user_guide')
        .upsert({ 
          id: 1, // Using a single row approach
          content: content,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;
      alert('Content saved successfully!');
    } catch (error) {
      console.error('Error saving content:', error);
      alert('Error saving content. Please try again.');
    }
  };

  useEffect(() => {
    const loadInitialContent = async () => {
      await checkAdminStatus();
      await fetchContent();
    };
    loadInitialContent();
  }, []);

  const checkAdminStatus = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        const { data: user } = await supabase
          .from('users')
          .select('is_admin')
          .eq('id', session.user.id)
          .single();
        
        setIsAdmin(user?.is_admin || false);
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const fetchContent = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_guide')
        .select('content')
        .eq('id', 1)
        .single();

      if (error) {
        console.error('Error fetching content:', error);
        return;
      }
      
      if (data && data.content) {
        console.log('Fetched content:', data.content);
        setContent(data.content);
      } else {
        console.log('No content found');
        setContent('');
      }
    } catch (error) {
      console.error('Error fetching content:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="user-guide-container">
      <div className="user-guide-header">
        <h1>User Guide</h1>
        {isAdmin && (
          <div className="header-controls">
            <button 
              className="edit-toggle"
              onClick={() => setIsEditing(!isEditing)}
              title={isEditing ? "Preview" : "Edit"}
            >
              {isEditing ? <IconEye size={20} /> : <IconEdit size={20} />}
            </button>
            {isEditing && (
              <button 
                className="save-button"
                onClick={saveContent}
                title="Save changes"
              >
                <IconDeviceFloppy size={20} />
              </button>
            )}
          </div>
        )}
      </div>
      
      <div className={`user-guide-content ${isEditing ? 'editing' : ''}`}>
        <MenuBar editor={editor} onSave={saveContent} isEditing={isEditing} addImage={addImage} />
        <div className="editor-wrapper">
          <EditorContent editor={editor} />
        </div>
      </div>
    </div>
  );
};

export default UserGuide;
