import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { IconSearch, IconEdit, IconTrash } from '@tabler/icons-react';
import '../../styles/pages/admin/UserManagement.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data, error } = await supabase.from('users').select('*');
        if (error) {
          console.error('Error fetching users:', error);
        } else {
          setUsers(data || []);
        }
      } catch (error) {
        console.error('Unexpected error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = (user) => {
    setSelectedUser({ ...user });
    setShowEditModal(true);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const { error } = await supabase.from('users').delete().eq('id', userId);
        if (error) {
          console.error('Error deleting user:', error.message);
          alert('Delete failed. Please check your permissions or try again later.');
        } else {
          setUsers(users.filter((user) => user.id !== userId));
          alert('User deleted successfully.');
        }
      } catch (error) {
        console.error('Unexpected error deleting user:', error.message);
        alert('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;

    try {
      const { data, error } = await supabase
        .from('users')
        .update({
          company_name: selectedUser.company_name,
          user_name: selectedUser.user_name,
          klaviyo_api_key: selectedUser.klaviyo_api_key,
          is_admin: selectedUser.is_admin,
          email: selectedUser.email,
        })
        .eq('id', selectedUser.id)
        .select();

      if (error) {
        console.error('Error updating user:', error.message);
        alert(`Update failed. Error: ${error.message}`);
      } else if (!data || data.length === 0) {
        console.error('Update returned no data.');
        alert('Update failed. Please check your permissions or try again later.');
      } else {
        setUsers(users.map((user) => (user.id === selectedUser.id ? data[0] : user)));
        setShowEditModal(false);
        setSelectedUser(null);
      }
    } catch (error) {
      console.error('Unexpected error updating user:', error.message);
      alert(`Unexpected error: ${error.message}`);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user &&
      (user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.user_name?.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="content">
      <div className="card">
        <h3 className="card-title">User Management</h3>

        <div className="search-container">
          <div className="search-bar-wrapper">
            <IconSearch size={20} className="search-icon" />
            <input
              type="text"
              className="search-input"
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className="table-container">
          <table className="users-table">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Klaviyo API Key</th>
                <th>Admin</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.company_name || '-'}</td>
                  <td>{user.user_name || '-'}</td>
                  <td>{user.email || '-'}</td>
                  <td>{user.klaviyo_api_key || 'N/A'}</td>
                  <td>{user.is_admin ? 'Yes' : 'No'}</td>
                  <td>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                      <button
                        className="btn-edit"
                        onClick={() => handleEdit(user)}
                        title="Edit user"
                      >
                        <IconEdit size={16} />
                      </button>
                      <button
                        className="btn-delete"
                        onClick={() => handleDelete(user.id)}
                        title="Delete user"
                      >
                        <IconTrash size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showEditModal && selectedUser && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h4 className="modal-title">Edit User</h4>
            <form onSubmit={handleSaveChanges}>
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedUser.company_name || ''}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, company_name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>User Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedUser.user_name || ''}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, user_name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={selectedUser.email || ''}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, email: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Klaviyo API Key</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedUser.klaviyo_api_key || ''}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, klaviyo_api_key: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedUser.is_admin || false}
                    onChange={(e) =>
                      setSelectedUser({ ...selectedUser, is_admin: e.target.checked })
                    }
                  />{' '}
                  Is Admin
                </label>
              </div>
              <div style={{ display: 'flex', gap: '0.5rem', marginTop: '1.5rem' }}>
                <button type="submit" className="btn-edit">
                  Save Changes
                </button>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() => {
                    setShowEditModal(false);
                    setSelectedUser(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
