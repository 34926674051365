import React from 'react';
import '../styles/pages/Settings.css';

const Settings = () => {
  return (
    <div className="content settings-container">
      <h2 className="settings-title">Settings</h2>
      <p className="coming-soon">Coming Soon...</p>
    </div>
  );
};

export default Settings;
