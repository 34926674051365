import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import Sidebar from './components/Sidebar';
import './styles/global.css';

// Import the pages
import Home from './pages/Home';
import LinkGenerator from './pages/LinkGenerator';
import Settings from './pages/Settings';
import UserGuide from './pages/UserGuide';
import Register from './pages/Register';
import Login from './pages/Login';
import PropertyBreakdown from './pages/PropertyBreakdown';

// Import the admin pages
import NewUser from './pages/admin/NewUser';
import UserManagement from './pages/admin/UserManagement';

function App() {
  const [session, setSession] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    // Function to initialize the session when the app loads
    const initializeSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session) {
          console.log("Initial session found:", session);
          setSession(session);
          fetchUserInfo(session.user.id);
        }
      } catch (error) {
        console.error("Error initializing session:", error);
      }
    };

    initializeSession();

    // Listen for authentication changes
    const { data: authListener } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session) {
        console.log("Auth state change detected:", session);
        setSession(session);
        fetchUserInfo(session.user.id);
      } else {
        setSession(null);
        setUserInfo(null);
      }
    });

    // Unsubscribe from the auth listener on cleanup
    return () => {
      if (authListener?.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  // Function to fetch user info from Supabase 'users' table
  const fetchUserInfo = async (userId) => {
    try {
      console.log('Fetching user info for user ID:', userId);
      
      // First, let's check if the user exists and how many rows we get
      const { data: checkData, error: checkError } = await supabase
        .from('users')
        .select('id')
        .eq('id', userId);

      if (checkError) {
        console.error('Error checking user:', checkError.message);
        setUserInfo(null);
        return;
      }

      console.log('Check query returned:', checkData?.length, 'rows');

      if (!checkData || checkData.length === 0) {
        console.error('No user found with ID:', userId);
        setUserInfo(null);
        return;
      }

      if (checkData.length > 1) {
        console.error('Multiple users found with ID:', userId);
        setUserInfo(null);
        return;
      }

      // Now fetch the full user data
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        console.error('Error fetching user info:', error.message);
        setUserInfo(null);
      } else {
        console.log('User info fetched:', data);
        setUserInfo({
          ...data,
          is_admin: data?.is_admin === true || data?.is_admin === "true", // Handle boolean or string 'true'
        });
      }
    } catch (err) {
      console.error('Unexpected error fetching user info:', err.message);
      setUserInfo(null);
    }
  };

  return (
    <Router>
      <div className="App">
        {session ? (
          <>
            <Sidebar isAdmin={userInfo?.is_admin} />
            <div className="content">
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/link-generator" element={<LinkGenerator />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/user-guide" element={<UserGuide />} />
                <Route path="/property-breakdown" element={<PropertyBreakdown />} />
                {userInfo?.is_admin && (
                  <>
                    <Route path="/admin/new-user" element={<NewUser />} />
                    <Route path="/admin/user-management" element={<UserManagement />} />
                  </>
                )}
                <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
