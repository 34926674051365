import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';

const NewUser = () => {
  const [companyName, setCompanyName] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [klaviyoApiKey, setKlaviyoApiKey] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Create a new user in Supabase Auth
      const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
        email: userEmail,
        password: userPassword,
      });

      if (signUpError) {
        setError(`Error creating user in Auth: ${signUpError.message}`);
        return;
      }

      if (signUpData?.user) {
        // Add user to the 'users' table
        const { error: insertError } = await supabase.from('users').insert([
          {
            id: signUpData.user.id,
            email: userEmail,
            company_name: companyName,
            user_name: userName,
            klaviyo_api_key: klaviyoApiKey,
            is_admin: false, // default to non-admin
          },
        ]);

        if (insertError) {
          setError(`Error adding user to database: ${insertError.message}`);
          return;
        }

        setSuccess('User created successfully!');
        // Clear input fields
        setCompanyName('');
        setUserName('');
        setUserEmail('');
        setUserPassword('');
        setKlaviyoApiKey('');
      }
    } catch (err) {
      setError(`Unexpected error: ${err.message}`);
    }
  };

  return (
    <div className="content">
      <div className="card p-4">
        <h3 className="card-title">Create New User</h3>
        <form onSubmit={handleCreateUser}>
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              className="form-control"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>User Name</label>
            <input
              type="text"
              className="form-control"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>User Email</label>
            <input
              type="email"
              className="form-control"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Klaviyo API Key</label>
            <input
              type="text"
              className="form-control"
              value={klaviyoApiKey}
              onChange={(e) => setKlaviyoApiKey(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">
            Create User
          </button>
          {error && <p className="text-danger mt-2">{error}</p>}
          {success && <p className="text-success mt-2">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default NewUser;
