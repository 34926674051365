import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/pages/Home.css';

const Home = () => {
  const navigate = useNavigate();
  const [klaviyoApiKey, setKlaviyoApiKey] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [customProperties, setCustomProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminStats, setAdminStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const [userName, setUserName] = useState('');

  // Use the Edge Function URL directly
  const klaviyoProxyUrl = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/klaviyo-proxy`;

  // Get first name (text before first space)
  const firstName = userName.split(' ')[0];

  const fetchKlaviyoApiKey = async () => {
    try {
      // Get session first
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (!session) {
        throw new Error('No session found');
      }

      console.log('Auth session user ID:', session.user.id);
      
      // Debug: Get all users to check IDs
      const { data: allUsers, error: allUsersError } = await supabase
        .from('users')
        .select('id, email')
        .limit(20);

      if (allUsersError) {
        console.error('Error fetching users:', allUsersError);
      } else {
        console.log('All users in database:', allUsers);
      }
      
      // Check for user data
      const { data: checkData, error: checkError } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id);

      if (checkError) {
        console.error('Error checking user:', checkError.message);
        setError('Failed to verify user');
        return;
      }

      if (!checkData || checkData.length === 0) {
        // Try finding user by email instead
        const { data: emailData, error: emailError } = await supabase
          .from('users')
          .select('*')
          .eq('email', session.user.email);

        if (!emailError && emailData && emailData.length > 0) {
          console.log('Found user by email instead of ID:', emailData[0]);
          // Update the user's ID in the database
          const { error: updateError } = await supabase
            .from('users')
            .update({ id: session.user.id })
            .eq('id', emailData[0].id);

          if (updateError) {
            console.error('Error updating user ID:', updateError);
          } else {
            console.log('Updated user ID successfully');
            // Use the user data we found
            const userData = emailData[0];
            if (userData.klaviyo_api_key) {
              setKlaviyoApiKey(userData.klaviyo_api_key);
              return;
            }
          }
        }
        
        console.error('No user found with ID:', session.user.id);
        setError('User not found in database');
        return;
      }

      // Use the first matching user's data
      const userData = checkData[0];
      
      if (!userData.klaviyo_api_key) {
        console.error('No Klaviyo API key found for user');
        throw new Error('No Klaviyo API key found for user');
      }

      console.log('Successfully fetched Klaviyo API key');
      setKlaviyoApiKey(userData.klaviyo_api_key);
    } catch (error) {
      console.error('Error fetching Klaviyo API key:', error);
      setError('Failed to fetch Klaviyo API key: ' + error.message);
    }
  };

  useEffect(() => {
    fetchKlaviyoApiKey();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const { data: userData, error } = await supabase
          .from('users')
          .select('user_name')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;
        setUserName(userData.user_name || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const { data: userData, error } = await supabase
          .from('users')
          .select('is_admin')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;
        setIsAdmin(userData.is_admin);

        if (userData.is_admin) {
          await fetchAdminStats();
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
      }
    };

    checkAdminStatus();
  }, []);

  const fetchAdminStats = async () => {
    setStatsLoading(true);
    try {
      // Get total user count
      const { data: users, error: usersError } = await supabase
        .from('users')
        .select('id, klaviyo_api_key');
      
      if (usersError) throw usersError;

      // Get all users' Klaviyo data
      const allPropertiesSet = new Set();
      const allValuesSet = new Set();
      let totalProfiles = 0;

      for (const user of users) {
        if (!user.klaviyo_api_key) continue;

        try {
          const { data: { session } } = await supabase.auth.getSession();
          if (!session) throw new Error('No session found');

          const response = await axios.post(klaviyoProxyUrl, {
            klaviyoApiKey: user.klaviyo_api_key,
            endpoint: '/profiles'
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session.access_token}`
            }
          });

          if (response.data && response.data.data) {
            totalProfiles += response.data.data.length;
            
            response.data.data.forEach(profile => {
              const attributes = profile.attributes;
              if (attributes && attributes.properties) {
                Object.entries(attributes.properties)
                  .forEach(([key, value]) => {
                    allPropertiesSet.add(key);
                    allValuesSet.add(typeof value === 'object' ? JSON.stringify(value) : String(value));
                  });
              }
            });
          }
        } catch (error) {
          console.error(`Error fetching data for user ${user.id}:`, error);
        }
      }

      setAdminStats({
        totalUsers: users.length,
        totalProperties: allPropertiesSet.size,
        totalValues: allValuesSet.size,
        totalProfiles: totalProfiles
      });

    } catch (error) {
      console.error('Error fetching admin stats:', error);
    } finally {
      setStatsLoading(false);
    }
  };

  useEffect(() => {
    const fetchKlaviyoData = async () => {
      if (!klaviyoApiKey) return;

      setLoading(true);
      setError(null);

      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) throw new Error('No session found');

        console.log('Making request with API key:', klaviyoApiKey);
        
        const response = await axios.post(klaviyoProxyUrl, {
          klaviyoApiKey: klaviyoApiKey,
          endpoint: '/profiles'
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session.access_token}`
          }
        });

        console.log('Response received:', response.data);

        // Extract unique custom properties from profiles
        const properties = new Set();
        if (response.data && response.data.data) {
          response.data.data.forEach(profile => {
            const attributes = profile.attributes;
            if (attributes && attributes.properties) {
              Object.keys(attributes.properties)
                .forEach(key => {
                  properties.add(key);
                });
            }
          });
        }

        // Convert properties Set to array of objects with metadata and sort alphabetically
        const propertyStats = Array.from(properties)
          .map(propName => {
            let count = 0;
            let example = null;

            // Count how many profiles have this property and get an example value
            response.data.data.forEach(profile => {
              if (profile.attributes?.properties?.[propName] !== undefined) {
                count++;
                if (!example) {
                  example = profile.attributes.properties[propName];
                }
              }
            });

            return {
              name: propName,
              count,
              example,
              type: typeof example
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically

        setCustomProperties(propertyStats);
        setProfileData(response.data);
      } catch (error) {
        console.error('Error details:', error.response?.data);
        setError(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchKlaviyoData();
  }, [klaviyoApiKey, klaviyoProxyUrl]);

  const handleViewProperty = (property) => {
    // Find profiles that have this property
    const profilesWithProperty = profileData.data.filter(profile => {
      const value = profile.attributes?.properties?.[property.name];
      return value !== undefined && value !== null;
    });

    const enrichedProperty = {
      ...property,
      profiles: profilesWithProperty.map(profile => ({
        id: profile.id,
        email: profile.attributes.email,
        value: profile.attributes.properties[property.name]
      }))
    };

    // Navigate to the property breakdown page with the property data
    navigate('/property-breakdown', { state: { property: enrichedProperty } });
  };

  return (
    <div className="content">
      <div className="welcome-message">
        <h1>Welcome, {firstName}</h1>
      </div>
      {loading && (
        <div className="loading">
          <p>Loading Klaviyo data...</p>
        </div>
      )}
      
      {error && (
        <div className="error-message">
          <p>Error: {error}</p>
        </div>
      )}

      {!loading && !error && (
        <div className="data-container">
          {isAdmin && (
            <div className="stats-section">
              <h2>Overall Statistics</h2>
              <div className="stats-grid">
                <div className="stat-card">
                  <h3>Users</h3>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalUsers || 0}</p>
                  )}
                </div>
                <div className="stat-card">
                  <h3>Properties</h3>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalProperties || 0}</p>
                  )}
                </div>
                <div className="stat-card">
                  <h3>Values</h3>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalValues || 0}</p>
                  )}
                </div>
                <div className="stat-card">
                  <h3>Profiles</h3>
                  {statsLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <p>{adminStats?.totalProfiles || 0}</p>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="user-data">
            <h3>Your Properties</h3>
            <div className="table-container">
              <div className="table-header">
                <h4>Custom Properties</h4>
                <span className="property-count">{customProperties.length} properties found</span>
              </div>
              <div className="table-wrapper">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Property Name</th>
                      <th>Profile Count</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customProperties.map(property => (
                      <tr key={property.name}>
                        <td className="property-name">{property.name}</td>
                        <td className="property-count">{property.count}</td>
                        <td>
                          <button 
                            className="view-button"
                            onClick={() => handleViewProperty(property)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
