import React from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { IconHome, IconLink, IconSettings, IconBook, IconUsers, IconLogout, IconMail, IconUserPlus } from '@tabler/icons-react';
import logo from '../assets/images/moralelogo.png';
import '../styles/components/Sidebar.css';

const Sidebar = ({ isAdmin }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error signing out:', error.message);
        return;
      }
      navigate('/login');
    } catch (err) {
      console.error('Unexpected error during logout:', err);
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-brand">
        <img src={logo} alt="Morale Marketer" className="sidebar-logo" />
      </div>
      <nav>
        <ul>
          <li><Link to="/home"><IconHome /> Dashboard</Link></li>
          <li><Link to="/link-generator"><IconLink /> Link Generator</Link></li>
          <li><Link to="/settings"><IconSettings /> Settings</Link></li>
          <li><Link to="/user-guide"><IconBook /> User Guide</Link></li>
          {isAdmin && (
            <>
              <li><Link to="/admin/new-user"><IconUserPlus /> New User</Link></li>
              <li><Link to="/admin/user-management"><IconUsers /> User Management</Link></li>
            </>
          )}
          <li><a href="mailto:aldwyn@moraleapp.co.uk" className="nav-link"><IconMail /> Contact</a></li>
        </ul>
        <div className="logout-container">
          <button onClick={handleLogout} className="btn-logout">
            <IconLogout /> Logout
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
