import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const { data: signUpData, error } = await supabase.auth.signUp({ email, password });
    
    if (error) {
      setError(error.message);
    } else {
      // Insert user data to the 'users' table
      const { user } = signUpData;
      if (user) {
        const { error: insertError } = await supabase
          .from('users')
          .insert([{ id: user.id, email, is_admin: false }]);
  
        if (insertError) {
          console.error('Error inserting user info:', insertError.message);
        }
      }
  
      navigate('/home'); // Redirect to Home after registration
    }
  };
  

  return (
    <div className="content">
      <div className="card p-4">
        <h3 className="card-title">Register</h3>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">Register</button>
          {error && <p className="text-danger mt-2">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Register;
